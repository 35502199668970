import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['otpField', 'submitButton', 'otpForm', 'hiddenInput']
  static OTP_LENGTH = 4

  connect() {
    this.setupEventListeners()
  }

  setupEventListeners() {
    this.otpFieldTargets[0].addEventListener('paste', this.handlePaste.bind(this))

    this.otpFieldTargets.forEach((input, index, inputs) => {
      input.addEventListener('input', () => this.handleInput(input, index, inputs))
      input.addEventListener('keydown', (event) => this.handleBackspace(event, input, index, inputs))
    })

    this.hiddenInputTarget.addEventListener('input', this.handleHiddenInput.bind(this))
    this.otpFormTarget.addEventListener('submit', this.updateHiddenInput.bind(this))
  }

  handlePaste(event) {
    const paste = (event.clipboardData || window.clipboardData).getData('text')

    if (paste.length === this.constructor.OTP_LENGTH) {
      this.fillOtpFields(paste)
      this.updateHiddenInput()
      this.focusLastField()

      if (this.allFieldsFilled()) {
        this.enableSubmitButton()
        this.submitForm()
      }
    }

    event.preventDefault()
  }

  handleInput(input, index, inputs) {
    if (input.value.length === 1 && index < inputs.length - 1) {
      inputs[index + 1].focus()
    }

    this.updateHiddenInput()

    if (this.allFieldsFilled()) {
      this.enableSubmitButton()
      this.submitForm()
    }
  }

  handleBackspace(event, input, index, inputs) {
    if (event.key === 'Backspace' && input.value === '' && index > 0) {
      inputs[index - 1].focus()
    }
  }

  handleHiddenInput() {
    const code = this.hiddenInputTarget.value
    if (code.length === this.constructor.OTP_LENGTH) {
      this.fillOtpFields(code)

      if (this.allFieldsFilled()) {
        this.updateHiddenInput()
        this.enableSubmitButton()
        this.submitForm()
      }
    }
  }

  fillOtpFields(value) {
    this.otpFieldTargets.forEach((input, index) => {
      input.value = value[index] || ''
    })
  }

  focusLastField() {
    this.otpFieldTargets[this.otpFieldTargets.length - 1].focus()
  }

  updateHiddenInput() {
    const otpValue = this.otpFieldTargets.map((input) => input.value).join('')
    this.hiddenInputTarget.value = otpValue
  }

  allFieldsFilled() {
    return Array.from(this.otpFieldTargets).every((input) => input.value.length === 1)
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.remove('cursor-not-allowed', 'opacity-50')
  }

  submitForm() {
    this.otpFormTarget.submit()
  }
}
