import ApplicationController from '../application_controller'
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    publicKey: String,
    clientSecret: String,
    stripeAccountId: String,
    returnUrl: String,
    billingDetails: Object,
    totalAmount: Number,
    mode: String,
    currency: String,
    confirmationTokenPath: String,
    systemErrorMessage: String,
  }

  connect() {
    /* global Stripe */
    this.stripe = Stripe(this.publicKeyValue, { stripeAccount: this.stripeAccountIdValue })

    const options = {
      mode: this.modeValue, // "subscription" or "payment"
      amount: this.totalAmountValue,
      currency: this.currencyValue,
      payment_method_types: ['card'],
      customerSessionClientSecret: this.clientSecretValue,
      // Fully customizable with appearance API.
      appearance: {
        /*...*/
      },
    }

    const paymentElementOptions = {
      layout: 'tabs',
      fields: {
        billingDetails: this.hasBillingDetails() ? 'never' : 'auto',
      },
    }

    this.elements = this.stripe.elements(options)
    this.paymentElement = this.elements.create('payment', paymentElementOptions)
    this.paymentElement.mount('#payment-element')
    this.messageContainer = document.querySelector('#payment-message')
  }

  async handleSubmit(e) {
    e.preventDefault()

    this.setLoading(true)
    this.hideErrorMessage()
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId)
    }

    const { error: submitError } = await this.elements.submit()
    if (submitError) {
      // This error should be shown by the payment element.
      this.setLoading(false)
      return
    }

    const { error, confirmationToken } = await this.stripe.createConfirmationToken({
      elements: this.elements,
      params: {
        payment_method_data: {
          billing_details: this.billingDetailsValue,
        },
      },
    })

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
      this.showErrorMessage(error.message)
      this.setLoading(false)
      return
    }

    // We have a confirmationToken, we will use it to create the payment.
    console.log('confirmationToken', confirmationToken)

    if (confirmationToken) {
      const request = new FetchRequest('PUT', this.confirmationTokenPathValue, {
        responseKind: 'json',
        body: JSON.stringify({
          confirmation_token: confirmationToken,
        }),
      })
      request
        .perform()
        .then((response) => response.json)
        .then((response) => {
          if (response.status === 'succeeded') {
            // Redirect to success page
            this.redirectToFinalUrl(response)
          } else if (response.status === 'requires_action') {
            // Redirect to 3D Secure page /  Show 3DS secure 2 modal.
            this.stripe
              .handleNextAction({
                clientSecret: response.client_secret,
              })
              .then(({ error, paymentIntent }) => {
                if (error) {
                  this.showErrorMessage(error.message)
                  this.setLoading(false)
                } else {
                  // Actions handled, redirect to success page
                  this.redirectToFinalUrl(response)
                }
              })
          } else {
            // Show an error message
            if (response.error) {
              this.showErrorMessage(response.error.message)
            }
            this.setLoading(false)
          }
        })
        .catch((error) => {
          this.showErrorMessage(this.systemErrorMessageValue)
          this.setLoading(false)
        })
    }
  }

  redirectToFinalUrl(response) {
    const url = new URL(this.returnUrlValue)
    url.searchParams.append('payment_intent', response.id)
    url.searchParams.append('payment_intent_client_secret', response.client_secret)
    Turbo.visit(url.toString())
  }

  setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector('#submit').disabled = true
      document.querySelector('#spinner').classList.remove('hidden')
      document.querySelector('#button-text').classList.add('hidden')
    } else {
      document.querySelector('#submit').disabled = false
      document.querySelector('#spinner').classList.add('hidden')
      document.querySelector('#button-text').classList.remove('hidden')
    }
  }

  showErrorMessage(messageText) {
    this.messageContainer.classList.remove('hidden')
    this.messageContainer.textContent = messageText

    this.hideTimeoutId = setTimeout(() => {
      this.hideErrorMessage()
    }, 5000)
  }

  hideErrorMessage() {
    this.messageContainer.classList.add('hidden')
    this.messageContainer.textContent = ''
  }

  hasBillingDetails() {
    return this.billingDetailsValue.address?.country?.trim() && this.billingDetailsValue.address?.postal_code?.trim()
  }
}
