import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['numberA', 'numberB']
  static values = {
    runOnConnect: { type: Boolean, default: true },
    selectorA: String,
    selectorB: String,
  }

  connect() {
    if (!this.runOnConnectValue) return

    this.update()
  }

  update() {
    const countA = this.element.querySelectorAll(this.selectorAValue).length
    this.numberATarget.innerText = countA

    const countB = this.element.querySelectorAll(this.selectorBValue).length
    this.numberBTarget.innerText = countB
  }
}
