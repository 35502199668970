import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['details']

  handleDetailsOpen(event) {
    this.detailsTargets.forEach((details) => {
      if (details !== event.target && details.open && !details.contains(event.target)) {
        details.hide()
      }
    })

    if (!event.target.querySelector('sl-details')) return

    event.target.querySelector("sl-details:has(button[data-completed='false'])").show()
  }
}
